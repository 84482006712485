import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import styled from "styled-components"
import theme from "@styles/theme"
import { graphql } from "gatsby"
import Image from "@components/image"
import { processImages } from "@utils/process-images"

const ImageWrapper = styled.div`
  text-align: center;
  .klipfolio-image-wrapper {
    border-radius: 0.5rem;
    box-shadow: ${theme.shadow.default};
  }
`

const StoryOfKlipfolioPage = ({ data }) => {
  const images = processImages(data.images.edges)

  const flexGap = { gap: "8rem", gapMd: "6rem" }

  return (
    <Layout
      fullWidth
      title={`Story of Klipfolio`}
      description={`The story of Klipfolio, from founding to Series B.`}
    >
      <Container>
        <Flex {...flexGap} margin="6rem 0" marginSm="4rem 0">
          <Container text>
            <Flex gap="1rem" center>
              <Heading as="h3">The Story of</Heading>
              <Image
                eager
                width={300}
                style={{ margin: "0 auto" }}
                objectFit="contain"
                file={images["f1d353dc-b5da-4792-9499-d2ac7757300a"]}
              />
              <Heading>From Founding To Series B</Heading>
            </Flex>
          </Container>
          <Grid alignItems="center" columns="3fr 2fr" gap="2rem">
            <Image
              style={{ margin: "0 auto" }}
              objectFit="contain"
              file={images["db8dd634-12a2-4691-926a-5c91c9eae7cb"]}
            />
            <Flex center gap="2rem">
              <Paragraph fontSize="1.4rem">
                Once upon a time, there was a software startup called{" "}
                <b>Klipfolio.</b>
              </Paragraph>
              <Heading as="h3">{`They're Canadian, eh.`}</Heading>
            </Flex>
          </Grid>
          <Flex center gap="2rem">
            <Heading as="h3">
              It saw that every business had the same question…
            </Heading>
            <Image
              style={{ margin: "0 auto" }}
              objectFit="contain"
              file={images["74451e1f-d998-4d21-b3bf-e7479eecc50b"]}
            />
          </Flex>
          <Grid gap="2rem" alignItems="center" columns="1fr 3fr">
            <Image
              objectFit="contain"
              width={300}
              height={300}
              file={images["7b071e51-f4e4-4d18-98f9-1f0446c6fffe"]}
            />
            <Paragraph>
              {`Answering that question wouldn’t be easy. But it should be, right?
              The trouble is you often need expertise and expensive tools to get
              at the data, not to mention someone to run the whole operation.
              Yikes!`}
            </Paragraph>
          </Grid>
          <Heading as="h3" center>
            So they asked themselves three questions…
          </Heading>
          <Grid gap="2rem" alignItems="center" columns="3fr 1fr">
            <Flex gap="2rem">
              <Paragraph>
                What if there was a way to easily pull all this data into one
                place, regardless if it was in the Cloud or on-premise?
              </Paragraph>
              <Paragraph>
                What if there was a way to automate data updates so the data is
                always current?
              </Paragraph>
              <Paragraph>
                What if there was a way to make it affordable enough for every
                business?
              </Paragraph>
            </Flex>
            <Image
              style={{ margin: "0 auto" }}
              objectFit="contain"
              width={300}
              height={300}
              file={images["0702413f-63f0-4aad-8efa-079ee5ae4f27"]}
            />
          </Grid>
          <Flex gap="4rem" center>
            <Flex gap="1rem">
              <Heading as="h5">2011</Heading>
              <Heading as="h4">
                In September 2011, Klipfolio made those “what ifs” a reality
              </Heading>
            </Flex>
            <ImageWrapper>
              <Image
                style={{ margin: "0 auto" }}
                objectFit="contain"
                width={650}
                file={images["80fdc95d-d33b-400e-9098-15b73f0a09b7"]}
              />
            </ImageWrapper>
            <Grid gap="2rem" columns="repeat(3, 1fr)" columnsMd="1fr">
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["7c9f600d-a40c-4142-a7d0-732968771448"]}
                />
                <Paragraph fontSize="1.1rem">
                  Klipfolio integrates with 100s of Cloud and on-premise
                  sources.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["da49eac8-8692-4879-8865-bfa039d2dc5e"]}
                />
                <Paragraph fontSize="1.1rem">
                  It automatically updates your data as frequently as a minute
                  or less through the API.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["3fa272d9-a703-4b7f-ba19-c94afa9cc243"]}
                />
                <Paragraph fontSize="1.1rem">
                  It’s affordable enough for any business. Sign up with a free
                  trial, purchase it with a credit card, and skip the need to
                  involve legal or procurement.
                </Paragraph>
              </Flex>
            </Grid>
          </Flex>
          <Flex gap="4rem" center>
            <Flex gap="1rem">
              <Heading as="h5">2014</Heading>
              <Heading as="h3">2014 was a big year</Heading>
            </Flex>
            <Grid gap="2rem" columns="repeat(3, 1fr)" columnsMd="1fr">
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["905c1137-d6f0-4c55-8214-694e14cad5be"]}
                />
                <Paragraph fontSize="1.1rem">
                  People loved it! They started spreading the love, and we grew.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["1ef61ba8-3632-4e03-9ab4-7d1f192afc39"]}
                />
                <Paragraph fontSize="1.1rem">
                  In January 2014, Klipfolio took a seed round of $1.7M to
                  invest in the platform.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["716d6928-ac50-4959-bfa1-a904be263614"]}
                />
                <Paragraph fontSize="1.1rem">
                  We added 22 Klipfolians, doubled our customer base, and hired
                  our Chief Cuddling Officer.
                </Paragraph>
              </Flex>
            </Grid>
          </Flex>
          <Flex gap="4rem" center>
            <Flex gap="1rem">
              <Heading as="h5">2015</Heading>
              <Heading as="h3">2015 saw some big developments</Heading>
            </Flex>
            <Grid gap="2rem" columns="repeat(3, 1fr)" columnsMd="1fr">
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["25b244d1-d181-4788-b62b-3fdad6b37574"]}
                />
                <Paragraph fontSize="1.1rem">
                  We launched the Klip Gallery, pre-built metrics to help
                  businesses quickly build dashboards.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["f1d2cb8b-a9f4-4247-b6a3-ad4a2191323a"]}
                />
                <Paragraph fontSize="1.1rem">
                  We held Dashboard Palooza, where we served cake and dashboards
                  to local businesses.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["684de048-e1cd-485b-a39f-bd8bcbb3d693"]}
                />
                <Paragraph fontSize="1.1rem">
                  We doubled our office space, and welcomed another 20
                  Klipfolians to the team.
                </Paragraph>
              </Flex>
            </Grid>
          </Flex>
          <Flex gap="2rem" center>
            <Image
              style={{ margin: "0 auto" }}
              objectFit="contain"
              file={images["a38eb66c-e6fb-4331-bb1b-da23ef631999"]}
            />
            <Heading as="h3">
              Klipfolio receives $6.2M in Series A funding
            </Heading>
          </Flex>
          <Flex gap="4rem" center>
            <Flex gap="1rem">
              <Heading as="h5">2016</Heading>
              <Heading as="h3">2016 was a huge year</Heading>
            </Flex>
            <Grid gap="2rem" columns="repeat(3, 1fr)" columnsMd="1fr">
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["d4017bd6-e41b-47e5-ad96-85ca2ffcedde"]}
                />
                <Paragraph fontSize="1.1rem">
                  We launched the Type-In Formula Bar to make building custom
                  dashboards more intuitive.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["b74e0e5b-61a4-497b-bf6e-a655c9b2e9d1"]}
                />
                <Paragraph fontSize="1.1rem">
                  We launched the Dashboard Gallery, pre-built dashboards to
                  delight our customers.
                </Paragraph>
              </Flex>
              <Flex gap="2rem">
                <Image
                  style={{ margin: "0 auto" }}
                  objectFit="contain"
                  height={128}
                  width={128}
                  file={images["d4017bd6-e41b-47e5-ad96-85ca2ffcedde"]}
                />
                <Paragraph fontSize="1.1rem">
                  We have over 7,000 happy customers.
                </Paragraph>
              </Flex>
            </Grid>
          </Flex>
          <Flex gap="2rem" center>
            <Image
              style={{ margin: "0 auto" }}
              objectFit="contain"
              file={images["c1480387-4cea-4003-8981-5ea7353636eb"]}
            />
            <Flex gap="1rem">
              <Heading as="h5">
                That’s just the start. We’ve reached a new milestone:
              </Heading>
              <Heading as="h3">
                Klipfolio receives $12M in Series B funding
              </Heading>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

StoryOfKlipfolioPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StoryOfKlipfolioPage

export const pageQuery = graphql`
  query StoryQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "f1d353dc-b5da-4792-9499-d2ac7757300a"
            "db8dd634-12a2-4691-926a-5c91c9eae7cb"
            "74451e1f-d998-4d21-b3bf-e7479eecc50b"
            "7b071e51-f4e4-4d18-98f9-1f0446c6fffe"
            "0702413f-63f0-4aad-8efa-079ee5ae4f27"
            "80fdc95d-d33b-400e-9098-15b73f0a09b7"
            "7c9f600d-a40c-4142-a7d0-732968771448"
            "da49eac8-8692-4879-8865-bfa039d2dc5e"
            "3fa272d9-a703-4b7f-ba19-c94afa9cc243"
            "905c1137-d6f0-4c55-8214-694e14cad5be"
            "1ef61ba8-3632-4e03-9ab4-7d1f192afc39"
            "716d6928-ac50-4959-bfa1-a904be263614"
            "25b244d1-d181-4788-b62b-3fdad6b37574"
            "f1d2cb8b-a9f4-4247-b6a3-ad4a2191323a"
            "684de048-e1cd-485b-a39f-bd8bcbb3d693"
            "a38eb66c-e6fb-4331-bb1b-da23ef631999"
            "6b28aeff-8fb8-4e71-b8db-d9d90c87a1ee"
            "b74e0e5b-61a4-497b-bf6e-a655c9b2e9d1"
            "d4017bd6-e41b-47e5-ad96-85ca2ffcedde"
            "c1480387-4cea-4003-8981-5ea7353636eb"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          placeholder
          title
        }
      }
    }
  }
`
